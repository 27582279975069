<template>
    <form class="needs-validation tab-pane fade show active" ref="form" autocomplete="off" id="signin-tab" >
        <div class="form-group">
            <label for="si-email">Email address</label>
            <input class="form-control" type="email" v-model="email" :rules="emailRules" label="E-mail"  id="si-email" placeholder="johndoe@example.com" required>
            <div class="invalid-feedback">Please provide a valid email address.</div>
        </div>
        <div class="form-group">
            <label for="si-password">Password</label>
            <div class="password-toggle">
                <input class="form-control" v-model="password" 
                    :rules="passwordRules" :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 6 characters"
                    counter
                     required>
                     
                  <label class="password-toggle-btn" @click="showPassword = true" >
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  <label class="password-toggle-btn" @click="showPassword = false" v-if="showPassword">
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
            </div>
        </div>
              <div class="form-group d-flex flex-wrap justify-content-between">
                <div class="custom-control custom-checkbox mb-2">
                  <!-- <input class="custom-control-input" type="checkbox" id="si-remember">
                  <label class="custom-control-label" for="si-remember">Remember me</label> -->
                </div>
                <!-- <a class="font-size-sm" href="#">Forgot password?</a> -->
              </div>
              <button class="btn btn-primary btn-block btn-shadow" data-dismiss="modal"  :disabled="!valid"
          @click="submit" >Sign in</button>
  </form>
      
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Login',
    data () {
        return {
            valid: true,
            email: '',
            emailRules: [
            v => !!v || 'E-mail is required',
            v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
            ],
            showPassword: false,
            password: '',
            passwordRules: [
            v => !!v || 'Password required.',
            v => (v && v.length >= 6) || 'Min 6 characters',
            ],
        }
    },
    computed: {
      ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl',
      }),
    },
    methods: {
        ...mapActions({
          setAlert  : 'alert/set',
          setAuth   : 'auth/set',
        }),
        showPass(){
          this.showPassword = !this.showPassword;
        },
        // hidePass(){
        //   this.showPassword = false;
        // },

        submit () {
            // if (this.$refs.form.validate()) {
                let formData = {
                    'email' : this.email,
                    'password' : this.password
                }

                this.axios.post('/login', formData)
                .then((response) => {
                    let { data } = response.data
                    this.setAuth(data)
                    if(this.user.id>0){
                        this.setAlert({
                            status : true,
                            color  : 'success',
                            text  : 'Login success',
                        })
                        if(this.prevUrl.length>0) this.$router.push(this.prevUrl) // tambahkan ini
                        this.close()
                    }
                    else{
                        this.setAlert({
                            status : true,
                            color  : 'danger',
                            text  : 'Login failed',
                        })
                    }
                })
                .catch((error) => {
                    let { data } = error.response
                    this.setAlert({
                        status : true,
                        color  : 'danger',
                        text  : data.message,
                    })
                })
            // }
        },
        close() {
            this.$emit('closed', false)
        }
    },
    
}
</script>